import './GiftTag.scss';

import { GIFT_TYPES } from '../../models/Gift.model';

export const GIFT_TAG_TYPES = ['all', ...GIFT_TYPES] as const;
export type GiftTagType = typeof GIFT_TAG_TYPES[number];

export type GiftTagTypeList = { [key in GiftTagType]: string }
export const GIFT_TAG_NAMES: GiftTagTypeList = {
  all: 'Todos',
  common: 'Presente',
  super: 'Super presente',
  mega: 'Mega presente',
};

export type GiftTagProps = {
  type: GiftTagType;
  isActive?: boolean;
  hasShadow?: boolean;

  onClick?: () => void;
}

const GiftTag = ({ type, isActive, hasShadow, onClick }: GiftTagProps) => {
  let className = `gift-type tag-${type}`;
  if (isActive) className += ' tag-active';
  if (hasShadow) className += ' tag-shadow';
  if (onClick && !isActive) className += ' tag-clickable';

  return (
    <div
      className={className}
      onClick={!isActive ? onClick : undefined}
    >
      {GIFT_TAG_NAMES[type]}
    </div>
  )
}

export default GiftTag