import { Gift } from "../../models/Gift.model";

export const GIFTS: Gift[] = [
  {type:'super', name:'Jogo de Panelas Tognana Glamour Bluestyled', details:'Azul, 4 peças', buyUrl:'https://www.camicado.com.br/p/jogo-de-panelas-tognana-glamour-bluestyled/-/A-100514623-br.lc?sku=100514631', imageUrl:'https://img.camicado.com.br/item/100514631/large/1.jpg', },
  {type:'common', name:'Jogo Americano 6 Peças + Caminho de Mesa Lizzi ', details:'Preto', buyUrl:'https://www.magazineluiza.com.br/jogo-americano-6-pecas-caminho-de-mesa-em-algodao-grid-preto-e-cru-moderno-mesa-posta-lizzi-home/p/hj9k54ffhj/ud/joam/', imageUrl:'https://a-static.mlcdn.com.br/800x560/jogo-americano-6-pecas-caminho-de-mesa-em-algodao-grid-preto-e-cru-moderno-mesa-posta-lizzi-home/lizzihomedecor/b4d1eadebf4211ed89804201ac18502f/32ab9fe50ec432fc4dbd4356196f5cef.jpeg', },
  {type:'common', name:'Kit com 12 Pecas de Cozinha Silicone', details:'Verde', buyUrl:'https://www.camicado.com.br/p/kit-com-12-pecas-de-cozinha-silicone-e-cabo-de-madeira-bambu-utensilios-jogo-bpa-free-espatula-colher-concha/-/A-7010700363228-br.lc?sku=7510700470632', imageUrl:'https://imgmarketplace.lojasrenner.com.br/20000/2856/7010700363228/7510700470632/0.jpeg', },
  {type:'mega', name:'Tapete Geométrico Retangular', details:'', buyUrl:'https://www.leroymerlin.com.br/tapete-geometrico-retangular-supreme-quadros-polipropileno-azul-e-laranja-interno-2x2,5m-tapetes-sao-carlos_90613054', imageUrl:'https://cdn.leroymerlin.com.br/products/tapete_supreme_quadros_laranja_e_azul_2,50x2,00m_90613054_e137_600x600.jpg', },
  {type:'mega', name:'Conjunto Mesa Pátio Redonda', details:'', buyUrl:'https://www.abracasa.com.br/mesa-patio-redonda-preto-nozes-87cm-2-cadeiras-patio-preto-nozes/p?idsku=2008562&gclid=Cj0KCQjwuNemBhCBARIsADp74QSZsuDoHCOk2MJe2kQSEUNfZb2OHAGAD3FDrWKLJKXAKR68nll5IgAaAihwEALw_wcB', imageUrl:'https://abracasa.vteximg.com.br/arquivos/ids/180815-1000-1000/conjunta-mesa-cadeira-acessorio.jpg?v=637883895821530000', },
  {type:'common', name:'Kit com 6 Cumbucas, Coleção Especiarias', details:'6 peças', buyUrl:'https://www.amazon.com.br/Acervo-Kit-Cumbucas-Panelinha-Multicor/dp/B07TN9QXN2/ref=asc_df_B07TN9QXN2/?tag=googleshopp00-20&linkCode=df0&hvadid=379720129404&hvpos=&hvnetw=g&hvrand=12968576908737743791&hvpone=&hvptwo=&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=1001778&hvtargid=pla-810816547869&psc=1', imageUrl:'https://m.media-amazon.com/images/I/41iVS6f6pjL._AC_SL1000_.jpg', },
  {type:'common', name:'Decoração de parede Umbra - Love Is All You Need', details:'', buyUrl:'https://www.amazon.com.br/dp/B00EV5BUD6/?coliid=I2BGMW142X9F0W&colid=1NW0LM9ZPHND0&ref_=list_c_wl_lv_ov_lig_dp_it&th=1', imageUrl:'https://m.media-amazon.com/images/I/71zRbFdWyYL._AC_SL1500_.jpg', },
  {type:'common', name:'Jogo de Cama Casal Percal', details:'Estampado Marinho', buyUrl:'https://www.amazon.com.br/dp/B09SP5D4LT/?coliid=I1GB0Y1K7LEQ66&colid=1NW0LM9ZPHND0&psc=1&ref_=list_c_wl_lv_ov_lig_dp_it', imageUrl:'https://m.media-amazon.com/images/I/71OJN8pVedL._AC_SL1500_.jpg', },
  {type:'mega', name:'Lava e Seca Midea HealthGuard ', details:'11kg Titanium', buyUrl:'https://www.magazinevoce.com.br/magazineguiadosmelhores/lava-e-seca-midea-11kg-mf200d110wb-smart-wi-fi-healthguard-14-programas-titanium/p/234659200/ed/lava/', imageUrl:'https://a-static.mlcdn.com.br/800x560/lava-e-seca-midea-11kg-mf200d110wb-smart-wi-fi-healthguard-14-programas-titanium/magazineluiza/234659200/c5f2a9ed9eb58283f8fbdc709666af9a.jpg', },
  {type:'super', name:'Pillow Top Antialergico Sossego', details:'Casal', buyUrl:'https://artex.com.br/pr/pillow-top-antialergico-sossego/ARSCAPLCZSOSBC?gclid=Cj0KCQjwz8emBhDrARIsANNJjS7WoGEkdpcQe4BzUeuFQTpmdExwp6MUz2c9nRhmgjd7AdWaYrgwf74aApvdEALw_wcB', imageUrl:'https://images-prod.artex.com.br/1536x1536/jpg/products/photos/still/arscaplczsosbc--1536px-1689359066585.jpg', },
  {type:'common', name:'Jogo de Cama Casal Percal', details:'Branco', buyUrl:'https://www.amazon.com.br/dp/B08HHD4JQP/?coliid=I3KX457I51B73A&colid=1NW0LM9ZPHND0&ref_=list_c_wl_lv_ov_lig_dp_it&th=1', imageUrl:'https://m.media-amazon.com/images/I/41sKK7ptFuL._AC_SL1000_.jpg', },
  {type:'mega', name:'Controle sem Fio Xbox Series', details:'Stellar Shift', buyUrl:'https://www.amazon.com.br/Controle-sem-Fio-Xbox-Stellar/dp/B0BV55WWMW/ref=asc_df_B0BV55WWMW/?tag=googleshopp00-20&linkCode=df0&hvadid=648356289804&hvpos=&hvnetw=g&hvrand=4209022168949489857&hvpone=&hvptwo=&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=1001778&hvtargid=pla-1967444169283&psc=1', imageUrl:'https://m.media-amazon.com/images/I/61YtJXbH46L._AC_SX522_.jpg', },
  {type:'common', name:'Repetidor TP-LINK Wi-Fi AC1200Mbps', details:'Branco', buyUrl:'https://www.amazon.com.br/dp/B075KFFSMR?tag=redescientia-20&linkCode=ogi&th=1&psc=1', imageUrl:'https://m.media-amazon.com/images/I/41Nj8RimaxL._AC_SL1000_.jpg', },
  {type:'common', name:'Mixer 3 em 1 Britânia BMX400P', details:'110v', buyUrl:'https://www.carrefour.com.br/mixer-3-em-1-britania-linha-cozinha-2-velocidades-400w-1-2l-bmx400p-preto-110v-6391150/p?utm_medium=sem&utm_source=google_pmax_1p&utm_campaign=1p_performancemax_Eletro_Eletroportateis&gclid=Cj0KCQjwuNemBhCBARIsADp74QS3McNQ_YEStJawESPyj5d655wbCK3EyG6f5Wf20N1vczgu4Da8zfMaAk4rEALw_wcB', imageUrl:'https://carrefourbr.vtexassets.com/arquivos/ids/22515426-1280-auto?v=637642939598130000&width=1280&height=auto&aspect=true', },
  {type:'common', name:'Relógio Parede 40cm Big', details:'Cromado', buyUrl:'https://www.tokstok.com.br/relogio-parede-40-cm-cromado-big/p?idsku=23103', imageUrl:'https://tokstok.vtexassets.com/arquivos/ids/1784744-553-553/Relogio-Parede-40-Cm-Cromado-Big.jpg?v=637007257611130000', },
  {type:'super', name:'Jogo de Panelas Tramontina Una', details:'Inox, 5 peças', buyUrl:'https://www.camicado.com.br/p/jogo-de-panelas-tramontina-una/-/A-100096476-br.lc?sku=100096484', imageUrl:'https://img.camicado.com.br/item/100096484/large/1.jpg', },
  {type:'common', name:'Conjunto 6 Pratos Fundos Madeleine', details:'Branco', buyUrl:'https://www.amazon.com.br/dp/B076QLTWJT/?coliid=I1WFK2C5KPYSY0&colid=1NW0LM9ZPHND0&psc=1&ref_=list_c_wl_lv_cv_lig_dp_it', imageUrl:'https://m.media-amazon.com/images/I/61pfFi8dhiL._AC_SL1000_.jpg', },
  {type:'common', name:'Bandeja Espelhada de Metal Luke', details:'Cobre', buyUrl:'https://now.westwing.com.br/bandeja-espelhada-de-metal-luke-acobreada-1274.html', imageUrl:'https://static-images-now.westwing.com.br/s/1274-8159-73710-1-product2.jpg', },
  {type:'common', name:'Jogo de Lençol Simples em Malha Hello Kitty Vintage', details:'Casal', buyUrl:'https://artex.com.br/pr/jogo-de-lencol-simples-em-malha-hello-kitty-vintage/MUESTJDCZ1VINTAG', imageUrl:'https://images-prod.artex.com.br/1536x1536/jpg/products/photos/semi-environment/jogo-de-lencol-simples-vintage-muestjdqz1vintag-almofada-aveluldada-face-kidsgalmzfp23ow_3721-1673011438347.jpg', },
  {type:'mega', name:'Kit Colcha Percal Matelassê Gatti', details:'Casal', buyUrl:'https://mmartan.com.br/pr/kit-colcha-percal-matelasse-gatti/MIMTLKCCZ1GATTI?utm_source=fb&utm_medium=%5Bfbads%5D&utm_campaign=display+%3A%3A+fb+%3A%3A+DPA+-+Prospecting+%3A%3A+LAL+Compradores+On+e+Off&utm_content=DPA+-+Prospecting+%3A%3A+All+products+-+Car+-+Price+tag%3A%3Afb&utm_id=23855770328380052&fbclid=IwAR0rxpGhzM-LjyU2-mUmkSP_WOASZj_4JF8N669RTg7I1w1ykdXfrDvca-Q', imageUrl:'https://images-prod.mmartan.com.br/1536x1536/jpg/products/photos/semi-environment/cama-16---mimtlkcsz1gatti-mm180jdsa1060-exmit1fpo21mraldbqmarv22az-cbmicpolt22bg_9908-1679431353797.jpg', },
  {type:'super', name:'Nintendo Switch - Gift Card Digital', details:'R$ 300', buyUrl:'https://www.kabum.com.br/produto/452490/gift-card-nintendo-300-reais-cartao-presente-digital', imageUrl:'https://images.kabum.com.br/produtos/fotos/452490/gift-card-nintendo-300-reais-cartao-presente-digital_1683224258_g.jpg', },
  {type:'super', name:'Edredom Matelado Dupla Face Margarida', details:'Queen', buyUrl:'https://artex.com.br/pr/edredom-matelado-dupla-face-margarida/TT151EDQZ1MARGA', imageUrl:'https://images-prod.artex.com.br/1536x1536/jpg/products/photos/semi-environment/tt151edqz1marga_detail_1-1669835187023.jpg', },
  {type:'super', name:'Kit Edredom Glamour Queen em Tecido - 7 Peças', details:'Cinza', buyUrl:'https://www.amazon.com.br/dp/B09NYJHGKH/?coliid=I26C2EU5AFQ3XJ&colid=1NW0LM9ZPHND0&psc=1&ref_=list_c_wl_lv_ov_lig_dp_it', imageUrl:'https://m.media-amazon.com/images/I/71KpDTV662L._AC_SL1080_.jpg', },
  {type:'common', name:'Toalha de Mesa Redonda Karsten Lótus Brisa', details:'6 lugares', buyUrl:'https://www.karsten.com.br/toalha-de-mesa-redonda-karsten-6-lugares-sempre-limpa-lotus-brisa-3812597/p?idsku=141877&gclid=Cj0KCQjwrfymBhCTARIsADXTabkiZIFaoEWkolDtKtaewNFrMFp_fKblgAqzE1dlNzvmmzr13ZBYFCEaAvj2EALw_wcB', imageUrl:'https://karsten.vtexassets.com/arquivos/ids/184189/3812597_02.jpg?v=638022085998600000', },
  {type:'mega', name:'Torre Arranhador para Gatos Tom', details:'Branco', buyUrl:'https://loja.ferpadesign.com.br/produtos/torre-arranhador-para-gatos-tom/?pf=gs&variant=343481892', imageUrl:'https://d3ugyf2ht6aenh.cloudfront.net/stores/001/306/522/products/torretom1-9613a72c0a9d5b6a4116467616195953-1024-1024.webp', },
  {type:'super', name:'Aparelho de Jantar Floreal Luiza 20pçs', details:'Oxford', buyUrl:'https://www.camicado.com.br/p/aparelho-de-jantar-floreal-luiza-20pcs-oxford/-/A-700376579-br.lc?sku=751154101', imageUrl:'https://imgmarketplace.lojasrenner.com.br/20000/1112/700376579/751154101/0.jpeg', },
  {type:'mega', name:'Sofa Cama Solteiro Futon Dobrável ', details:'Cinza', buyUrl:'https://www.amazon.com.br/dp/B07PBZW7XD/?coliid=I2L2YQTZRGBELR&colid=1NW0LM9ZPHND0&psc=1&ref_=list_c_wl_lv_ov_lig_dp_it', imageUrl:'https://m.media-amazon.com/images/I/61nXU0RdYyL._AC_SL1280_.jpg', },
  {type:'common', name:'Carregador Pilhas Recarregáveis Duracell + 4 Pilhas AA', details:'', buyUrl:'https://www.magazineluiza.com.br/carregador-de-pilhas-recarregaveis-aa-e-aaa-duracell-com-4-pilhas-aa/p/226737100/cj/ilha/', imageUrl:'https://a-static.mlcdn.com.br/1500x1500/carregador-de-pilhas-recarregaveis-aa-e-aaa-duracell-com-4-pilhas-aa/magazineluiza/226737100/46a708a97fca1f7a783d8623c410c2b8.jpg', },
  {type:'common', name:'Jogo de Taças Diamante Rainbow', details:'6 peças', buyUrl:'https://www.amazon.com.br/sspa/click?ie=UTF8&spc=MTozMjA1NTQ2NjYwNDE1ODAzOjE2OTIzODU3NjM6c3BfYnRmOjMwMDAyMDY4NDYyNTYwMjo6MDo6&url=%2FTa%25C3%25A7as-Diamante-Rainbow-Class-Home%2Fdp%2FB09GYMLGWK%2Fref%3Dsr_1_33_sspa%3F__mk_pt_BR%3D%25C3%2585M%25C3%2585%25C5%25BD%25C3%2595%25C3%2591%26crid%3D1RYLAFP4IPUHL%26keywords%3Dpote%2Bsobremesa%26qid%3D1692385763%26s%3Dkitchen%26sprefix%3Dpot%252Ckitchen%252C292%26sr%3D1-33-spons%26sp_csd%3Dd2lkZ2V0TmFtZT1zcF9idGY%26psc%3D1', imageUrl:'https://m.media-amazon.com/images/I/51pONg7s4DL._AC_SL1000_.jpg', },
  {type:'common', name:'Jogo para Banheiro em Porcelana Dylan', details:'03 pçs', buyUrl:'https://now.westwing.com.br/jogo-para-banheiro-em-porcelana-dylan-44504.html', imageUrl:'https://static-images-now.westwing.com.br/s/44504-3323-88285-1-product2.jpg', },
  {type:'mega', name:'Mesa Tech Regulagem Elétrica', details:'110v', buyUrl:'https://www.flexform.com.br/moveis/escritorio/mesa-de-escritorio/mesa-tech-desk-regulagem-eletrica', imageUrl:'https://assets.betalabs.net/production/flexform/item-images/475816b30d173ad2b34e95677e650355.png', },
  {type:'super', name:'Kit Colcha Matelassê Dupla Face BBB Pop', details:'Queen', buyUrl:'https://artex.com.br/pr/kit-colcha-matelasse-dupla-face-bbb-pop/ABBB5KCQZ1POP', imageUrl:'https://images-prod.artex.com.br/1536x1536/jpg/products/photos/semi-environment/_0028__lcl4566-1jpg-1651006416801.jpg', },
  {type:'super', name:'Espelho Orgânico Preto', details:'38x89 cm', buyUrl:'https://www.mobly.com.br/espelho-organico-preto-38x89-cm-1013964.html?related-product=DA270AC37RYOMOB#t=product-group', imageUrl:'https://static.mobly.com.br/p/Dalla-Costa-Espelho-OrgC3A2nico-Preto-38x89-cm-1673-4693101-1-zoom.jpg', },
  {type:'common', name:'Kit Churrasco Tramontina Polywood', details:'3 peças', buyUrl:'https://www.magazineluiza.com.br/kit-churrasco-tramontina-polywood-3-pecas/p/235026200/ud/cjxu/', imageUrl:'https://a-static.mlcdn.com.br/1500x1500/kit-churrasco-tramontina-polywood-3-pecas/magazineluiza/235026200/3666005e4b78412609724230bc490ac1.jpg', },
  {type:'common', name:'Panela Wok Paris 36cm Tramontina', details:'Vermelha', buyUrl:'https://www.camicado.com.br/p/panela-wok-vermelha-paris-36cm-20545736-tramontina/-/A-701706237-br.lc?sku=755103470', imageUrl:'https://s3-sa-east-1.amazonaws.com/images.anymarket.com.br/259028014./A0270A014E1D98078A110B366E24CDB9/standard_resolution.jpg', },
  {type:'mega', name:'Mesa com Regulagem de Altura GenioDesk PLUS', details:'160 x 70', buyUrl:'https://www.geniodesks.com.br/mesa-regulagem-eletrica/mesa-com-regulagem-de-altura-geniodesk-plus/produto-mesa-com-regulagem-de-altura-geniodesk-plus?fee=4&fep=338&gad=1&gclid=Cj0KCQjwuNemBhCBARIsADp74QTuLnZn8b_I7ElxdGKro9ljZXhK7pnLtHZ4lGwVS9_KIlxW7eCwf6oaAoVqEALw_wcB', imageUrl:'https://www.geniodesks.com.br/media/catalog/product/cache/1/image/9df78eab33525d08d6e5fb8d27136e95/t/a/tampo_branco_base_plus_branca_2.jpg', },
  {type:'common', name:'Jogo para Banheiro Yuri Granilite', details:'', buyUrl:'https://now.westwing.com.br/jogo-para-banheiro-yuri-granilite-l-12910.html', imageUrl:'https://static-images-now.westwing.com.br/s/12910-9826-42081-1-product2.jpg', },
  {type:'common', name:'Caixona de Areia Pet Games', details:'Bege', buyUrl:'https://www.amazon.com.br/dp/B0BKLNTBYX?coliid=I2PNWELRP0A0K4&colid=2V2E29X2PVHDL&psc=1&linkCode=sl1&tag=isagateira0d-20&linkId=521009fa678738cb6a69f88f4f400096&language=pt_BR&ref_=as_li_ss_tl&fbclid=PAAaYqp7GRzfUyNDMRy00k_j9v-5ryeh-lnh2aqcWiIgAWPXMcDFu7-tp1L7w', imageUrl:'https://m.media-amazon.com/images/I/51KNtZT4O3L._AC_SL1500_.jpg', },
  {type:'common', name:'Wok com Tampa Garlic 24 cm Brinox', details:'Vermelho', buyUrl:'https://www.amazon.com.br/dp/B076T1BNSZ/?coliid=IOQ6PN6TKY4SZ&colid=1NW0LM9ZPHND0&ref_=list_c_wl_lv_ov_lig_dp_it&th=1', imageUrl:'https://m.media-amazon.com/images/I/81JH7rH8JcL._AC_SL1500_.jpg', },
  {type:'mega', name:'Kit Gatificação 07', details:'', buyUrl:'https://acasaedogato.com.br/produtos/kit-gatificacao-07/?variant=657881795', imageUrl:'https://d3ugyf2ht6aenh.cloudfront.net/stores/002/058/796/products/design-sem-nome-2023-07-17t101407-54431-7478a0aa6c0cc8a46e16896004031590-640-0.webp', },
  {type:'common', name:'Tapete Passadeira Antiderrapante 130cm x 45cm ', details:'Mosaico Preto', buyUrl:'https://www.amazon.com.br/dp/B096MWYYLM/?coliid=IHRJDM58VO0QO&colid=1NW0LM9ZPHND0&psc=1&ref_=list_c_wl_lv_ov_lig_dp_it', imageUrl:'https://m.media-amazon.com/images/I/61K9KqflbUL._AC_SL1000_.jpg', },
  {type:'super', name:'Kit Jogo Americano Orgânico', details:'Verde e caramelo, 6 peças', buyUrl:'https://www.solatoconcept.com/mesa-posta/jogo-americano/jogo-americano-verde-militar-e-caramelo-dupla-face-organico-florenca', imageUrl:'https://images.tcdn.com.br/img/img_prod/1104285/90_jogo_americano_verde_militar_e_caramelo_dupla_face_organico_florenca_499_1_720595ab7ae9e941f95635f2acb49ae3.jpg', },
  {type:'super', name:'Tapete Turco Manhattan Bingol ', details:'150 x 190', buyUrl:'https://now.westwing.com.br/tapete-turco-manhattan-bingol-14969.html', imageUrl:'https://static-images-now.westwing.com.br/s/14969-4055-18702-1-product2.jpg', },
  {type:'common', name:'Cama de Janela Catbed Malibu para Gatos', details:'Tam. M', buyUrl:'https://gatomiu.petlove.com.br/cama-catbed-malibu-para-gatos/p?sku=2562705', imageUrl:'https://www.petlove.com.br/images/products/231753/hd_no_extent/Cama_Catbed_Malibu_para_Gatos_2562704.jpg?1627743270', },
  {type:'common', name:'Tapetinho + Passadeira Trajeto', details:'2 peças', buyUrl:'https://artex.com.br/pr/tapetinho--passadeira-trajeto/BD.2PC.TRAJETO', imageUrl:'https://images-prod.artex.com.br/1536x1536/jpg/products/photos/still/bd2pctrajeto-1650290926055.jpg', },
  {type:'common', name:'Jogo Toalhas de Banho - 5 peças', details:'Rosé', buyUrl:'https://www.amazon.com.br/dp/B09XY6KTN3/?coliid=I2EFP3EKYQ4Q0R&colid=1NW0LM9ZPHND0&ref_=list_c_wl_lv_ov_lig_dp_it&th=1', imageUrl:'https://m.media-amazon.com/images/I/719gl-QsqdL._AC_SL1500_.jpg', },
  {type:'super', name:'Escadinha Toca Dona Gatolina', details:'', buyUrl:'https://donagatolina.lojavirtualnuvem.com.br/produtos/escadinha-toca/', imageUrl:'https://donagatolina.lojavirtualnuvem.com.br/produtos/escadinha-toca/', },
];
