import './LineText.scss';

export type LineTextColor = 'pink' | 'purple'
export type LineTextProps = {
  lineColor?: LineTextColor,
  text: string,
  hasBigText?: boolean,
}

const LineText = ({ lineColor, text, hasBigText }: LineTextProps) => {
  let lineClass = 'line-wrapper';
  if (lineColor) lineClass += ` ${lineColor}-line`;

  let textClass = 'text';
  if (hasBigText) textClass += ' big-text';

  return <div className='line-text-wrapper'>
    <div className={lineClass}><hr/></div>
    <div className={textClass}>{ text }</div>
    <div className={lineClass}><hr/></div>
  </div>
}

export default LineText