import './SideIcon.scss'

import { ReactNode } from 'react'
import { PathUtils } from '../../utils';

export type SideIconProps = {
  icon: string;
  children: ReactNode;
  className?: string;
  hasBigIcon?: boolean;
}

const SideIcon = ({ icon, children, className = '', hasBigIcon }: SideIconProps) => {
  const iconClass = hasBigIcon ? 'icon big-icon' : 'icon';

  return <div className={'side-icon-wrapper ' + className}>
    <div className='wrapper'>
      <img className={iconClass} src={PathUtils.getIconUrl(icon)} alt={icon} />
    </div>

    <div className='wrapper'>
      <div className='content'>{children}</div>
    </div>
  </div>
}

export default SideIcon