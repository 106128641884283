import './Button.scss'

import { PathUtils } from '../../utils'

export type ButtonType = 'glass' | 'white' | 'only-border'
export type ButtonProps = {
  className?: string,
  type: ButtonType,

  label: string,
  icon?: string,

  onClick?: () => void,

  hasBigText?: boolean,
}

const Button = ({
  className, type,
  label, icon,
  onClick,
  hasBigText,
}: ButtonProps) => {
  let fullClass = `button-big ${type}`;
  if (className) fullClass += ' ' + className
  if (hasBigText) fullClass += ' big-text'

  const iconObj = icon ? <img className='button-icon me-3' src={PathUtils.getIconUrl(icon)} alt={label}></img> : undefined

  return <button
    className={fullClass}
    onClick={onClick}
  >
    <span className='button-content'>
      { iconObj } <p>{label}</p>
    </span>
  </button>
}

export default Button