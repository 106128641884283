import { Modal, SideIcon } from "../../../components"

import { SpecificModalProps } from "../../../components/modal/Modal"

const TimelineModal = ({visible, onClose}: SpecificModalProps) => {
  return <Modal
    title='Cronograma e cardápio'
    icon='cardapio'
    visible={visible}
    onClose={onClose}
  >
    <div className='specific-modal'>
      <SideIcon hasBigIcon className='mb-4' icon='comida'>
        <h2>Em breve!</h2>
      </SideIcon>
    </div>
  </Modal>
}

export default TimelineModal