import './Intro.scss'

import { useNavigate } from "react-router-dom";

import { Button } from '../../components'

const Intro = () => {
	const navigate = useNavigate();

	return <div className='intro-page'>
		<div className='flowers'>
			<img className='top' src='/assets/images/flower-top.svg' alt='Flores roxas e rosas' />
			<img className='bottom' src='/assets/images/flower-bottom.svg' alt='Flores roxas e rosas' />
		</div>

		<div className='content-wrapper'>
			<p className='welcome'>Boas-vindas ao nosso casamento!</p>
			<h1 className='names'>Larissa & Gabriel</h1>
			<Button
				type='glass'
				className='mt-3'
				label={'Entrar'}
				onClick={() => navigate('/home')}
				hasBigText={true}
			></Button>
		</div>
	</div>
}

export default Intro