import './Gifts.scss'

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { PathUtils } from '../../utils'
import GiftCard from '../../components/gift-card/GiftCard'
import { GIFTS } from './Gifts.data'
import GiftTag, { GIFT_TAG_TYPES, GiftTagType } from '../../components/gift-tag/GiftTag'
import { Gift } from '../../models/Gift.model'
import { ButtonSmall } from '../../components'

const GIFTS_PER_PAGE = 4;
const GET_PAGE_AMOUNT = (len: number) => Math.ceil(len / GIFTS_PER_PAGE);

const Gifts = () => {
  const navigate = useNavigate();
  const [currentFilter, setCurrentFilter] = useState<GiftTagType>('all');
  const [filteredData, setFilteredData] = useState<Gift[]>(GIFTS);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageAmount, setPageAmount] = useState<number>(GET_PAGE_AMOUNT(filteredData.length));

  const goToMenu = () => navigate('/home');
  const changeFilter = (newFilter: GiftTagType) => {
    let filtered = GIFTS;
    if (newFilter !== 'all') filtered = GIFTS.filter((gift) => gift.type === newFilter);

    setFilteredData(filtered);
    setCurrentPage(0);
    setPageAmount(GET_PAGE_AMOUNT(filtered.length));
    setCurrentFilter(newFilter);
  }

  const previousPage = () => setCurrentPage(currentPage - 1);
  const nextPage = () => { setCurrentPage(currentPage + 1) };

  const paginatedData = filteredData.slice(currentPage * GIFTS_PER_PAGE, currentPage * GIFTS_PER_PAGE + GIFTS_PER_PAGE);

	return <div className='gifts-page'>
    <div className="back-wrapper">
      <button className="back" onClick={goToMenu}>
        <img alt="Voltar" src={PathUtils.getIconUrl('esquerda')}></img> VOLTAR
      </button>
    </div>

    <div className="gifts-wrapper">
      <div className='title'>
        <img src={PathUtils.getIconUrl('presente')} alt={'Ideias de presentes'} />
        <h3>Ideias de presentes</h3>
      </div>

      <div className="filter-wrapper">
        { GIFT_TAG_TYPES.map(type => (
          <GiftTag
            type={type}
            hasShadow
            isActive={type === currentFilter}
            onClick={() => changeFilter(type)}
          />
        )) }
      </div>

      <div className="list-wrapper">
        {paginatedData.map(gift => <GiftCard key={gift.name + gift.details} gift={gift}></GiftCard>)}
      </div>

      <div className="pagination-wrapper">
        <ButtonSmall
          label="Anterior"
          icon="esquerda"
          onClick={previousPage}
          disabled={currentPage <= 0}
        />

        <span>{filteredData.length ? currentPage + 1 : '0'} de {pageAmount}</span>

        <ButtonSmall
          label="Próximo"
          icon="direita"
          iconPosition="after"
          onClick={nextPage}
          disabled={currentPage >= pageAmount - 1}
        />
      </div>
    </div>
	</div>
}

export default Gifts