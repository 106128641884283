import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';

import { Intro, Menu, Gifts } from './pages';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: '/',
    element: <Intro />
  },
  {
    path: '/home',
    element: <Menu />
  },
  {
    path: '/gifts',
    element: <Gifts />
  },
]);

root.render(
  <React.StrictMode>
    <div className="container">
      <RouterProvider router={router}/>
    </div>
  </React.StrictMode>
);

