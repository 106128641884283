import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Modal, SideIcon } from "../../../components"

import { SpecificModalProps } from "../../../components/modal/Modal"
import { ButtonProps } from "../../../components/button/Button";

const GiftModal = ({visible, onClose}: SpecificModalProps) => {
  const [isPixCopied, setIsPixCopied] = useState(false);
  const navigate = useNavigate();

  const pix = 'pixdocasal@gabtoschi.com';
  const copyPix = () => {
    navigator.clipboard.writeText(pix);
    setIsPixCopied(true);
  }

  const closeAndResetPix = () => {
    setIsPixCopied(false);
    onClose();
  }

  const buttons: ButtonProps[] = [
    {
      type: 'white',
      label: !isPixCopied ? 'Copiar chave Pix' : 'Chave Pix copiada',
      icon: 'aneis',
      onClick: copyPix,
    },
    {
      type: 'white',
      label: 'Acessar lista completa',
      icon: 'surpresa',
      onClick: () => navigate('/gifts'),
    }
  ];

  return <Modal
    title='Ideias de presentes'
    icon='presente'
    buttons={buttons}
    visible={visible}
    onClose={closeAndResetPix}
  >
    <div className='specific-modal'>
      <h4 className='mb-2'>Primeiro de tudo, obrigada por querer nos presentear!</h4>
      <p className="mb-3">
        Preparamos listas de itens que precisamos, mas não precisa ser nos sites que escolhemos!
        Fique a vontade para comprar o que achar mais adequado.
      </p>

      <h4 className='mb-2'>Nosso endereço para entregas</h4>
      <SideIcon className='mb-3' icon='placa'>
        <p>Rua Doutor Souza Alves, 130, ap. 1403. Bairro Centro, Taubaté/SP. CEP 12020-030</p>
      </SideIcon>

      <h4 className='mb-2'>Se preferir, você pode nos enviar um Pix!</h4>
      <p>
        Nossa chave Pix: <b>{pix}</b>
      </p>

    </div>
  </Modal>
}

export default GiftModal