import './ButtonSmall.scss'

import { PathUtils } from '../../utils';

export type IconPosition = 'before' | 'after';
export type ButtonSmallProps = {
  label: string;
  icon?: string;
  iconPosition?: IconPosition;

  disabled?: boolean;
  onClick: () => void;
}

const ButtonSmall = ({ label, onClick, disabled, icon, iconPosition = 'before' }: ButtonSmallProps) => {
  const iconObj = icon ? <img className={iconPosition} alt={label} src={PathUtils.getIconUrl(icon)}/> : <></>;

  return (
    <button className={`button-small ${disabled && 'disabled'}`} onClick={!disabled ? onClick : undefined}>
      {iconPosition === 'before' && iconObj}
      {label}
      {iconPosition === 'after' && iconObj}
    </button>
  )
}

export default ButtonSmall