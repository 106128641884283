import './Menu.scss'

import { useState } from 'react'

import { Button, LineText } from '../../components'
import { ButtonProps } from '../../components/button/Button'

import { LocationModal, AttendanceModal, TimelineModal, GiftModal } from './specific-modal'

const Menu = () => {
  const [openedModal, setOpenedModal] = useState<string | null>(null);
  const closeModal = () => setOpenedModal(null);

  const menuButtons: Omit<ButtonProps, 'type'>[] = [
    { label: 'Local e horário', icon: 'local', onClick: () => setOpenedModal('location') },
    { label: 'Confirmar presença', icon: 'presenca', onClick: () => setOpenedModal('attendance') },
    { label: 'Cronograma e cardápio', icon: 'cardapio', onClick: () => setOpenedModal('timeline') },
    { label: 'Ideias de presentes', icon: 'presente', onClick: () => setOpenedModal('gift') },
  ]

	return <div className='menu-page'>
    <div className='flower-wrapper'>
      <img className='flower' src='/assets/images/flower-menu.svg' alt='Flores roxas e rosas' />
    </div>

    <div className='menu-content'>
      <div className='menu-header'>
        <div className='icon-wrapper'>
          <img className='header-icon' src='/assets/icons/passaros.svg' alt='Passarinhos que se amam' />
        </div>

        <LineText
          text='Sua presença é muito importante para nós'
          lineColor='pink'
        />

        <h1 className='names'>Larissa & Gabriel</h1>

        <LineText
          text='14 de outubro de 2023'
          lineColor='purple'
          hasBigText={true}
        />
      </div>

      <div className='button-list-wrapper row'>
        { menuButtons.map((buttonData, index) => (
          <div key={index} className='col-6'>
            <div className={'button-content-wrapper ' + (index % 2 === 0 ? 'even-button' : 'odd-button')}>
              <Button
                {...buttonData}
                type='glass'
             />
            </div>
          </div>
        )) }
      </div>
    </div>

    <LocationModal visible={openedModal === 'location'} onClose={closeModal} />
    <AttendanceModal visible={openedModal === 'attendance'} onClose={closeModal} />
    <TimelineModal visible={openedModal === 'timeline'} onClose={closeModal} />
    <GiftModal visible={openedModal === 'gift'} onClose={closeModal} />
	</div>
}

export default Menu