import './Modal.scss';

import { ReactNode } from 'react';

import Button, { ButtonProps } from '../button/Button';
import { PathUtils } from '../../utils'

export type ModalProps = {
  title: string;
  icon: string;
  children?: ReactNode;

  buttons?: ButtonProps[];

  visible?: boolean;
  onClose: () => void;
}

export type SpecificModalProps = Pick<ModalProps, 'visible' | 'onClose'>

const Modal = ({
  title, icon, children,
  buttons = [],
  visible = false,
  onClose,
}: ModalProps) => {
  if (!visible) return <></>

  const allButtons: ButtonProps[] = [
    ...buttons,
    {
      type: 'only-border',
      label: 'Voltar para o início',
      onClick: onClose,
    }
  ]

  return <div className='modal-wrapper'>
    <div className='modal-content'>
      <span className="modal-close" onClick={onClose}>X</span>

      <div className='modal-title'>
        <img src={PathUtils.getIconUrl(icon)} alt={title} />
        <h3>{ title }</h3>
      </div>

      <div className='modal-text'>
        {children}
      </div>

      <div className='modal-buttons'>
        {allButtons.map((buttonData, index) => {
          return <Button className='w-100' key={index} {...buttonData} />
        })}
      </div>
    </div>
  </div>
}

export default Modal