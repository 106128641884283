import './GiftCard.scss';

import { Gift } from '../../models/Gift.model';
import GiftTag from '../gift-tag/GiftTag';
import ButtonSmall from '../button-small/ButtonSmall';

export type GiftCardProps = {
  gift: Gift,
}

const GiftCard = ({ gift }: GiftCardProps) => {
  const giftImageInlineStyle = {
    backgroundImage: `url(${gift.imageUrl})`,
  }

  const openLink = () => window.open(gift.buyUrl, '_blank');

  return <div className='gift-card'>
    <div className="gift-image" style={giftImageInlineStyle}></div>
    <div className="gift-data">
      <div className="gift-data-top">
        <GiftTag type={gift.type} />
        <div className="gift-name">{gift.name}</div>
      </div>
      <div className="gift-data-bottom">
        <div className="gift-details">
          {gift.details && <>
            <span className="gift-details-label">TIPO</span>
            <span className="gift-details-info">{gift.details}</span>
          </>}
        </div>
        <ButtonSmall label="Acessar site" icon="externo" onClick={openLink} />
      </div>
    </div>
  </div>
}

export default GiftCard