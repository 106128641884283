import { Modal, SideIcon } from "../../../components"
import { ButtonProps } from "../../../components/button/Button"

import { SpecificModalProps } from "../../../components/modal/Modal"

const AttendanceModal = ({visible, onClose}: SpecificModalProps) => {
  const whatsappUrl = 'https://wa.me/5516996274780';

  const buttons: ButtonProps[] = [
    {
      type: 'white',
      label: 'Confirmar presença por WhatsApp',
      icon: 'presenca',
      onClick: () => window.open(whatsappUrl, '_blank'),
    }
  ];

  return <Modal
    title='Confirmar presença'
    icon='presenca'
    buttons={buttons}
    visible={visible}
    onClose={onClose}
  >
    <div className='specific-modal'>
      <SideIcon hasBigIcon className='mb-4' icon='convite'>
        <h2>Ficamos muito felizes com a sua presença nesse momento especial!</h2>
      </SideIcon>

      <p className="mb-4">Avise para nosso cerimonialista que você e sua família estarão presentes via WhatsApp, no telefone <b>(16) 99627-4780</b>.</p>

      <h5>Você pode confirmar sua presença até dia 14/09/2023!</h5>
    </div>
  </Modal>
}

export default AttendanceModal