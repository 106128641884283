import { Modal, SideIcon } from "../../../components"
import { ButtonProps } from "../../../components/button/Button"

import { SpecificModalProps } from "../../../components/modal/Modal"

const LocationModal = ({visible, onClose}: SpecificModalProps) => {
  const googleMapsUrl = 'https://maps.app.goo.gl/BL5BawXPNXdetqBV6';

  const buttons: ButtonProps[] = [
    {
      type: 'white',
      label: 'Acessar no Google Maps',
      icon: 'pin',
      onClick: () => window.open(googleMapsUrl, '_blank'),
    }
  ];

  return <Modal
    title='Local e horário'
    icon='local'
    buttons={buttons}
    visible={visible}
    onClose={onClose}
  >
    <div className='specific-modal'>
      <h4 className='mb-2'>Nosso casamento será no...</h4>

      <SideIcon className='mb-5' icon='mapa'>
        <h5>Buffet Sabor e Requinte</h5>
        <p>Endereço: R. José Meireles, 85 - Jardim Nossa Sra. Aparecida, São Carlos - SP</p>
      </SideIcon>

      <SideIcon className='mb-5' icon='aneis'>
        <p>O início da cerimônia será no dia <b>14 de outubro</b> às <b>18h</b>. A cerimônia vai acontecer em local aberto e depois iremos para a festa no salão.</p>
      </SideIcon>

      <SideIcon icon='terno'>
        <p>Recomendamos traje esporte fino.</p>
      </SideIcon>
    </div>
  </Modal>
}

export default LocationModal